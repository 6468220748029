import React from "react";
import ContactButton from "../../components/contactButton";
import HeaderDivideTextLeft from "../../components/headerDivideTextLeft";
import Layout from "../../components/layout";
import Row from "starkeydigital/React/Components/row";
import Accordion from "starkeydigital/React/Components/accordion";
interface props {}
const ConferenceSpeaking: React.FC<props> = (props) => {
  return (
    <Layout
      titleText="Conference Speaking"
      titleText2=""
      slogan="We speak at management and partner conferences covering keynote topics."
    >
      <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Conference Speaking"
        text="We deliver keynote speeches at management and partner conferences, in
        plenary sessions and at client events and workshops."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row>

      <Row style={{marginTop: "60px"}}>
        <div className="col">
          <Accordion>
            <Accordion.Item title="What topics do you cover for Conference Speaking?">
              Subject matters include: Diversity in the Workplace, Inside
              Knowledge: How Women Can Thrive in Professional Service Firms, Top
              Ten Tips on Being Successful.
            </Accordion.Item>
          </Accordion>
        </div>
      </Row>
    </Layout>
  );
};
export default ConferenceSpeaking;
